<template lang="pug">
div.conversaBtn(@click="showConversa()")
  div.conversa_collapsed_button.ml-1(v-if="floating")
    a(href="#", class="hvr-grow")
      img(:src="get_img_url('ico_conversa_float.png')" class="conversa_collapsed_button__icoFloatConversa")
  div.conversa_expanded_button(v-else)
    a(href="#", class="w-100 hvr-shrink")
      .conversa__container
        .d-flex.align-items-center.justify-content-around.no-gutters.w-100
          div(:class="['d-flex flex-row align-items-start justify-content-center conversa__logoConversa']")
            img(v-if="current_user_materia" :src="get_img_url('logo_Conversa_Color_Part.png')" class="conversa__logoConversa--ico--Part")
            img(v-else :src="get_img_url('logo_Conversa_Color.png')" class="conversa__logoConversa--ico")
          div.conversa__titleMateria
            p.m-0(v-if="current_user_materia" :class="['conversa__titleMateria__text', formattedMateria]") {{ renderMateria }}
            p.m-0(v-else)



</template>
<script>
import { mapMutations } from 'vuex'
import mixins from "common/mixins"
export default {
  name: 'conversa-button-vue',
  mixins: [mixins.translate],
  props: {
    floating: { type: Boolean, default: true },
    current_user_materia: { type: String, default: '' },
  },
  computed: {
    formattedMateria() {
      return this.current_user_materia
        ? this.current_user_materia
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s+/g, '_')
            .toLowerCase()
        : '';
      },
    renderMateria() {
      return this.current_user_materia || '';
    }
  },
  methods: {
    ...mapMutations('conversa', ['setShowConversa']),
    showConversa() {
      this.setShowConversa(true);
    },
    get_img_url(img_name) {
      return require('../../../assets/images/conversa/' + img_name);
    },
  }
}
</script>

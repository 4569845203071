<template lang="pug">
.container
  .row
    .col-12
      .row.align-items-center.border.border-primary.border-radius.rounded.mt-5.mb-3.py-3
        .col
          | {{ t('components.boletines_judiciales.form_label') }}
        .col-auto
          year-month-selector-vue(:first="first" :last="last", v-model="selected")
  .row.bg-light.rounded.p-4(v-if="!error && !loading")
    .col-3.mt-4.d-flex.flex-column.align-items-center.justify-content-start
      img.shadow(
        :src="coverUrl"
        style="width: 200px;"
        data-cy="boletin_cover"
      )
      a.btn.btn-primary.mt-3(
        :href="pdfUrl"
        data-cy="download_button"
      )
        | {{ t('components.boletines_judiciales.download') }}
    div.col-9.mt-n4
      template
        slot
  .row.bg-light.rounded.p-5.justify-content-center(v-if="error")
    | {{ t('components.boletines_judiciales.not_found') }}
  .row.bg-light.rounded.p-5.justify-content-center(v-if="loading")
    .row-info-search
      .searching.mb-5
        span.searching-text
          | {{ t('components.search.searching') }}
        span.load
</template>

<script>
import mixins from 'common/mixins'
import { mapMutations, mapActions } from 'vuex'
import ApiClient from 'common/ApiClient'

export default {
  name: 'boletines-judiciales-main-vue',
  props: {
    first: { type: String, default: '' },
    last: { type: String, default: '' }
  },
  mixins: [ mixins.filters, mixins.translate ],
  components: {
    'year-month-selector-vue': () => import(
      /* webpackChunkName: "[request]" */ 'components/forms/YearMonthSelector'
    )
  },
  data: function () {
    return {
      selected: this.last,
      pdfUrl: undefined,
      coverUrl: undefined,
      error: false,
      loading: false
    }
  },
  methods: {
    async fetchData() {
      this.pdfUrl = undefined
      this.coverUrl = undefined
      this.error = false

      await this.loadBoletin()
      if (!this.error) {
        this.setFilters([
          this.new_filter(
            'fecha',
            [this.firstDate(this.selected), this.lastDate(this.selected)],
            'RANGEDATES'
          ),
          this.new_filter(
            'origen_latam_sfacet',
            ['Suprema Corte de Justicia']
          )
        ])
        this.performSearch()
      }
    },
    async loadBoletin() {
      this.loading = true
      const api = new ApiClient()
      const response = await api.get(
        gon.url_for.boletines_judiciales_show_path.replace(':id', this.selected)
      )
      if (response.ok) {
        this.pdfUrl = response.data.pdf
        this.coverUrl = response.data.cover
      } else {
        this.error = true
      }
      this.loading = false
    },
    firstDate(yearmonth) {
      var date = new Date(this.getYear(yearmonth), this.getMonth(yearmonth) - 1)
      return this.formatDate(date)
    },
    lastDate(yearmonth) {
      var date = new Date(this.getYear(yearmonth), this.getMonth(yearmonth))
      date.setDate(date.getDate() - 1)
      return this.formatDate(date)
    },
    getMonth(data) {
      return parseInt(data.split('-')[1])
    },
    getYear(data) {
      return parseInt(data.split('-')[0])
    },
    formatDate(date) {
      var year = date.getFullYear()
      var month = String(date.getMonth() + 1).padStart(2, '0')
      var day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    ...mapMutations('search', ['setFilters']),
    ...mapActions('search', ['performSearch']),
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    selected() {
      this.fetchData()
    }
  }
}
</script>

<template lang="pug">
  .row.justify-content-center
    #documental_assistant_form.col-12(v-if="show_form")
      loader-vue(:visible='sending_request')
      p.documental_Assistant__title_Form {{ t('documental_assistant.form_title') }}
      form
        .row.mt-3
          .col-12.col-lg-6.mb-3.mb-lg-0
            input#input_name.form-control(type='text' :placeholder='name_place_holder' v-model='name')
          .col-12.col-lg-6
            input#input_last_name.form-control(type='text' :placeholder='last_name_place_holder' v-model='last_name')
        .row.mt-3
          .col-12.col-lg-6.mb-3.mb-lg-0
            input#input_city.form-control(type='text' :placeholder='city_place_holder' v-model='city')
          .col-12.col-lg-6
            input#input_zip_code.form-control(type='zip' :placeholder='zip_code_place_holder' v-model='zip_code')
        .row.mt-3
          .col-12.col-lg-6.mb-3.mb-lg-0
            input#input_phone.form-control(type='tel' :placeholder='phone_place_holder' v-model='phone')
          .col-12.col-lg-6
            input#input_email.form-control(type='email' :placeholder='email_place_holder' v-model='email')
        .row.mt-3
          .col-12
            textarea#input_text.form-control(:placeholder='text_place_holder' :maxlength="text_maxlength" v-model='text' rows='5')
            forms-voice-button-vue(
             :maxlength="text_maxlength"
             @update-text="handleUpdatedText"
            )
        .row.mt-3
          .col-12
            forms-lopd-text-vue
            forms-messages-list-vue(:messages='errors' :title='errors_title')
        .row.mt-3
          .col-12.d-flex.justify-content-end
            button#submit_documental_assistant_form.btn.btn-primary.submit(@click='submitForm')
              | {{ t('documental_assistant.send') }}
    #request_response_container.login_form_per(v-else)
      .alert.alert-success(v-if="!request_error")
        p.message-success(v-html="t('documental_assistant.submit_message')")
      .alert.alert-danger(v-else)
        p.message-error(v-html="t('documental_assistant.submit_message_error')")
      .d-flex.justify-content-center
        a#back_to_form.btn.btn-primary.submit(href='#' @click='() => show_form = !show_form') {{ t('documental_assistant.back_form') }}
</template>

<script>
import Vue from "vue"
import { mapState, mapMutations, mapActions } from "vuex"
import mixins from "common/mixins"
import constants from "common/constants.js"
import ApiClient from "common/ApiClient"
import Validator from "common/Validator"

export default {
  mixins: [mixins.translate, mixins.requests],
  name: "documental-assistant-form-vue",
  components: {
    "forms-lopd-text-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/LopdText"),
    "forms-messages-list-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList"),
    "loader-vue": () => import(/* webpackChunkName: "[request]" */ "components/Loader"),
    "forms-voice-button-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/VoiceButton")
  },
  data() {
    return {
      name: "",
      last_name: "",
      city: "",
      zip_code: "",
      phone: "",
      email: "",
      text: "",
      contact_source: 'Se lo buscamos',
      sending_request: false,
      show_form: true,
      errors: {},
      service_bus: new Vue(),
      request_error: false,
      name_place_holder: I18n.t('documental_assistant.placeholders.name'),
      last_name_place_holder: I18n.t('documental_assistant.placeholders.last_name'),
      city_place_holder: I18n.t('documental_assistant.placeholders.city'),
      zip_code_place_holder: I18n.t('documental_assistant.placeholders.zip_code'),
      phone_place_holder: I18n.t('documental_assistant.placeholders.phone'),
      email_place_holder: I18n.t('documental_assistant.placeholders.email'),
      text_place_holder: I18n.t('documental_assistant.placeholders.text'),
      errors_title: I18n.t('documental_assistant.errors_title'),
      phone_min_length: constants.PHONE_MIN_LENGTH,
      phone_max_length: constants.PHONE_MAX_LENGTH,
      text_maxlength: constants.TEXTAREA_MAX_LENGTH
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      if (this.validateForm()) {
        this.sendData()
      }
    },
    validateForm() {
      let validator_params = [
        {
          fields: I18n.t("documental_assistant.fields.name"),
          values: this.name,
          validates: "filled",
        },
        {
          fields: I18n.t("documental_assistant.fields.last_name"),
          values: this.last_name,
          validates: "filled",
        },
        {
          fields: I18n.t("documental_assistant.fields.city"),
          values: this.city,
          validates: "filled",
        },
        {
          fields: I18n.t("documental_assistant.fields.zip_code"),
          values: this.zip_code,
          validates: "filled",
        },
        {
          fields: I18n.t('documental_assistant.fields.phone'),
          values: this.phone,
          params: [this.phone_min_length, this.phone_max_length],
          validates: "length_between",
        },
        {
          fields: I18n.t("documental_assistant.fields.email"),
          values: this.email,
          validates: "email_format",
        },
        {
          fields: I18n.t("documental_assistant.fields.text"),
          values: this.text,
          validates: "filled",
        },
        {
          fields: I18n.t("documental_assistant.fields.check_info"),
          values: this.lopd_privacy,
          validates: "checked",
        }
      ]
      let validator = new Validator(validator_params).do()

      if (!validator.success) {
        this.setErrors(validator.messages)
        return false
      }
      return true
    },
    async sendData() {
      this.sending_request = true
      let payload = {
        nombre: this.name,
        apellidos: this.last_name,
        poblacion: this.city,
        codigo_postal: this.zip_code,
        telefono: this.phone,
        email: this.email,
        texto: this.text,
        origen: this.contact_source,
        lopd_privacidad: this.lopd_privacy,
        lopd_grupo: this.lopd_group,
        lopd_comercial: this.lopd_publicity
      }

      await this.saveCommercialContact(payload)
      if (this.saveCommercialContactFailed) {
        this.requestFailed()
        return
      }

      const sendMailResponse = await this.sendDocumentalAssistantEmail(payload)
      if (sendMailResponse.failed) {
        this.requestFailed()
        return
      }

      this.requestSuccess()
    },
    requestSuccess() {
      this.request_error = false
      this.clearForm()
      this.hideForm()
    },
    requestFailed() {
      this.request_error = true
      this.sending_request = false
      this.hideForm()
    },
    async sendDocumentalAssistantEmail(payload) {
      const api = new ApiClient()
      const response = await api.post(gon.url_for.documental_assistant_send_mail, payload)

      return response
    },
    setErrors(errors) {
      this.errors = errors
    },
    clearForm() {
      this.name = ""
      this.last_name = ""
      this.city = ""
      this.zip_code = ""
      this.phone = ""
      this.email = ""
      this.text = ""
      this.errors = {}
      this.clearLopd()
    },
    hideForm() {
      this.sending_request = false
      this.show_form = false
    },
    handleUpdatedText(processedText) {
      this.text = processedText
    },
    ...mapMutations('lopd_texts', ['clearLopd']),
    ...mapActions('commercial_contact', { saveCommercialContact: 'saveRequest'})
  },
  computed: {
    ...mapState('lopd_texts', [
      'lopd_privacy',
      'lopd_group',
      'lopd_publicity'
    ]),
    ...mapState('commercial_contact', { saveCommercialContactFailed: 'saveRequestFailed'})
  },
}
</script>
